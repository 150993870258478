export default function NotFound() {
  return (
    <div className='not-found-container'>
      <h1 className='not-found-title'>404</h1>
      <p className='not-found-subtitle'>
        Sorry, the requested page {`\n`} couldn't be found
      </p>
    </div>
  );
}
